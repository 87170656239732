import React, {useState, useEffect} from "react";
import buttonStyles from '../../../pages/TrackingLinks/TrackingLinksPage.module.scss';
import styles from './AddProductDetails.module.scss';
import {Button, Box, Typography} from "@material-ui/core";
import {useUploadRequest} from "../../../features/API/request";
import {useTranslation} from 'react-i18next';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import nonHookRequest from "../../../features/API/nonHookRequest";
import {toast} from 'react-toastify';
import {useDebouncedCallback} from "use-debounce";
import InputAdornment from "@material-ui/core/InputAdornment";
import Loader from 'react-loader-spinner';

interface AddProductDetailsProps {
  handleAddingDetails: () => void;
  productTitle: string | null;
  productLink: string | null;
  productLogo: string | null;
  setProductTitle: React.Dispatch<React.SetStateAction<string | null>>;
  setProductLink: React.Dispatch<React.SetStateAction<string | null>>;
  setProductLogo: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AddProductDetails = ({
  handleAddingDetails,
  productTitle,
  productLink,
  productLogo,
  setProductTitle,
  setProductLink,
  setProductLogo,
}: AddProductDetailsProps) => {
  const [uploading, setUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const uploadFileRequest = useUploadRequest({method: 'POST', url: '/linksFile/upload'});

  const {t} = useTranslation();

  useEffect(() => {
    if (productLink) {
      handlefetchLinkInfo();
    };
  }, [productLink]);

  const fetchTrackingLinkInfo = async () => {
    setIsLoading(true);
    const response = await nonHookRequest({method: 'GET', url: '/albumLinks/meta', params: {
      url: productLink,
    }});
    if (response) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(response, 'text/html');
  
      const ogTitleTag = doc.querySelector('meta[property="og:title"]');
      const ogImageTag = doc.querySelector('meta[property="og:image"]');
  
      const title = ogTitleTag?.getAttribute('content') || '';
      const imageUrl = ogImageTag?.getAttribute('content') || '';

      setProductTitle(title);
      setProductLogo(imageUrl);
    };
    setIsLoading(false);
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const params = {
        file: {
          key: 'file',
          value: file,
          fileName: file.name,
        },
      };
      setUploading(true);
      const response = await uploadFileRequest.doRequest(params);
      if (response.length) {
        setUploading(false);
        setProductLogo(response[0].url);
      }
    }
  };

  const isValidURL = (url: string) => {
    const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    return regex.test(url);
  };

  const handlefetchLinkInfo = useDebouncedCallback(() => {
    fetchTrackingLinkInfo();
  }, 1000);

  return (
    <div className={styles.container}>
      <h5>{t('add_product_details')}</h5>
      <p>{t('add_product_details_note')} </p>

      <OutlinedInput
        className={styles.customInput}
        value={productLink}
        onChange={(e) => setProductLink(e.target.value)}
        endAdornment={isLoading ? 
          <InputAdornment position="start">
            <Loader type="TailSpin" color="#EB3B5A" height={20} width={20} />
          </InputAdornment> : 
        null}
        placeholder={t('add_product_link')}
      />

      <OutlinedInput
        className={styles.customInput}
        value={productTitle}
        onChange={(e) => setProductTitle(e.target.value)}
        placeholder={t('add_product_title')}
        disabled={isLoading}
      />

      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{display: 'none'}}
        id="file-upload"
        disabled={isLoading}
      />
      <label htmlFor="file-upload">
        <Button 
          variant="contained" 
          component="span" 
          color="primary" 
          className={styles.addImageButton}
        >
          {t('upload_image')}
        </Button>
      </label>
      {uploading && <Typography>{t('uploading')}</Typography>}
      {productLogo && (
        <Box mt={2}>
          <Typography variant="h6">{t('preview')}:</Typography>
          <img
            src={productLogo as string}
            alt="Preview"
            className={styles.productLogo}
          />
        </Box>
      )}
      <span className={styles.heightSpan}></span>
      <div className={styles.buttonContainer}>
        <button 
          className={buttonStyles.cta} 
          disabled={!productTitle || !productLink || !productLogo}
          onClick={() => {
            if (isValidURL(productLink!)) {
              handleAddingDetails();
            } else {
              toast.error(t('invalid_product_link'));
            }
          }}
        >
          {t('add_product')}
        </button>
      </div>
    </div>
  );
};
