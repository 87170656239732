import React, {useEffect, useState} from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styles from './ChargeCardModal.module.scss';
import {useTranslation, Trans} from "react-i18next";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Loader from 'react-loader-spinner';
import {isArabic} from "../../features/util";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {SectionItemContentType} from "../../constants/helper";
import nonHookRequest from "../../features/API/nonHookRequest";
import {DetailedSuccessModalContent, GenericSuccessModalContent} from "../ChargeCardTapModal/Helpers";

export const CardForm = ({monetizeID, monetizeObj, theme, setStep, step, visitorData, setTitle, handleClose}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentIntentLoaded, setPaymentIntentLoaded] = useState(false);
  const [linkSectionItem, setLinkSectionItem] = useState<any>();
  const [paymentState, setPaymentState] = useState('');
  const [termsOfService, setTermsOfService] = useState(false);
  const {t} = useTranslation();
  const queryEmail: any = new URLSearchParams(window?.location?.search)?.get("email");
  const urlParams = new URLSearchParams(window.location.search);
  const newSuccessModalLinkTypes = ['sellProductGuide', 'sellProduct'];

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret",
    );

    if (!clientSecret || monetizeID) {
      return;
    }
    setTitle ? setTitle('request_submitted') : '';
    stripe.retrievePaymentIntent(clientSecret).then(async ({paymentIntent}: any) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setPaymentState("succeeded");
          if (monetizeObj.type == 'shortCall' && !monetizeID && new URLSearchParams(window.location.search).get("contentType") === SectionItemContentType.CALENDLY) {
            setStep('bookingSlot');
            setTitle('book_a_slot');
          }
          if (newSuccessModalLinkTypes.includes(urlParams.get('linkType')!)) {
            const response = await nonHookRequest({
              method: 'GET',
              url: `/finance/api/stripe/get-charge-id/${paymentIntent.id}`,
              isShortUrl: true,
            });
            
            if (response?.chargeId) {
              const linkAttachments = await nonHookRequest({
                method: 'GET',
                url: `/finance/api/orders/link-attachments/${response?.chargeId}`,
                isShortUrl: true,
              });

              setLinkSectionItem(linkAttachments);
            }
          }
          break;
        case "processing":
          setMessage("Your payment is processing.");
          setPaymentState('processing');
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}${window.location.pathname}?email=${encodeURIComponent(visitorData.email)}&linkType=${monetizeObj.type}&bookUrl=${monetizeObj.url}&name=${encodeURIComponent(visitorData?.name)}&notes=${encodeURIComponent(visitorData?.notes)}&contentType=${monetizeObj?.contentType}`,
        payment_method_data: {
          billing_details: {
            name: visitorData?.name,
            email: visitorData?.email,
            phone: visitorData?.phone,
            address: {
              line1: visitorData?.notes,
            },
          },
        },
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error && error?.type) {
      setMessage(error.message);
      setPaymentState('error');
    } else {
      setMessage("An unexpected error occured.");
      setPaymentState('error');
    }

    setIsLoading(false);
  };

  return monetizeID && !message ? (
    <>
    <form className={step == 'card' ? styles.padTop : ''}>
      <div className={step == 'form' ? styles.hideCard : ''}><PaymentElement onReady={() => {
        setPaymentIntentLoaded(true);
      }} /></div>
      {step == 'card' && paymentIntentLoaded && <div className={`${styles.row} ${styles.terms}`}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsOfService}
              onChange={() => setTermsOfService(!termsOfService)}
              name="checkedB"
              color="primary"
            />
          }
          label={<Trans i18nKey=''>
          <div>Accept <a href="https://app.al.fan/terms-and-condition-buyer" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://app.al.fan/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></div>
        </Trans>}
        />
      </div>}
      {paymentIntentLoaded && <><button type="button" onClick={(e: any) => {
          handleSubmit(e);
      }} className={styles.button} disabled={isLoading || !stripe || !elements || (step == 'form' && (!!!visitorData.name.length || !!!visitorData.email.length)) || (step == 'card' && !termsOfService)} id="submit">
        <span className={styles.button_text}>
          {isLoading ? <div className={styles.spinner}></div> : (step == 'form' ? 'Next' : t('pay_now'))}
        </span>
      </button></>}
    </form>
    </>
  ) : (<>
      {/* Show any error or success messages */}
      {message && <div className={styles.payment_done}>
         {paymentState == 'processing' && <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />}
         {paymentState == 'succeeded' && 
          newSuccessModalLinkTypes.includes(urlParams.get('linkType')!) ? 
          <DetailedSuccessModalContent theme={theme} handleClose={handleClose} linkSectionItem={linkSectionItem} /> : 
          <GenericSuccessModalContent theme={theme} />}
         {paymentState != 'succeeded' && <div className={styles.payment_message}>{message}</div>}
        </div>}
      </>);
};
