import React, {useEffect, useState, useMemo} from 'react';
import styles from '../ChargeCardModal/ChargeCardModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {Trans, useTranslation} from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {GoSell} from '@tap-payments/gosell';
import CancelIcon from '@material-ui/icons/Cancel';
import {isArabic} from '../../features/util';
import 'react-phone-input-2/lib/high-res.css';
import ar from 'react-phone-input-2/lang/ar.json';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import nonHookRequest from '../../features/API/nonHookRequest';
import {toast} from 'react-toastify';
import CalendarBooking from '../CalendarBooking/CalendarBooking';
import {getCurrencyLabel} from '../../constants/currencies';
import Preview from '../ChargeCardModal/Preview';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import CheckboxField from './CheckboxField';
import InputField from './InputField';
import LoaderComponent from './LoaderComponent';
import SubmitButton from './SubmitButton';
import TipJar from './TipJar';
import SafeHtmlDisplay from '../LinkModal/components/SafeHtmlDisplay';
import TextInput from '../LinkModal/components/TextInput';
import BookCallSlotPicker from '../BookCallSlotPicker/BookCallSlotPicker';
import moment from 'moment';
import {SectionItemContentType} from '../../constants/helper';
import {DetailedSuccessModalContent, GenericSuccessModalContent} from './Helpers';


interface IProps {
  handleClose: () => void;
  clientSecret: any;
  // setCientSecret: React.Dispatch<React.SetStateAction<any>>;
  monetizeID: string;
  theme?: any;
  monetizeObj: any;
  userId?: any;
  linksUser: any;
}
const mIDMap: any = {
  usd: 'tapUsdMID',
  aed: 'tapAedMID',
  sar: 'tapSarMID',
};

const ChargeCardTapModal = ({
  handleClose,
  monetizeID,
  theme,
  monetizeObj,
  clientSecret,
  userId,
  linksUser,
}: IProps) => {
  const currencyLabel = getCurrencyLabel(monetizeObj.currency);
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(
      'form',
  );
  const [visitorData, setVisitorData] = useState<any>({
    name: '',
    email: '',
    notes: '',
    tipJarAmount: null,
    isCustom: false,
    phone: '',
    countryCode: '',
  });
  const [visitorDataError, setVisitorDataError] = useState({
    name: '',
    email: '',
    tipJarAmount: '',
    phone: '',
    countryCode: '',
  });
  const [title, setTitle] = useState('');
  const [tapStatus, setTapStatus] = useState('');
  const [tapMsg, setTapMsg] = useState('');
  const [linkSectionItem, setLinkSectionItem] = useState<any>();
  const [termsOfService, setTermsOfService] = useState(false);
  const [selectionObj, setSelectedObj] = useState<any>({activeDuration: '', activeSlot: '', timezone: '', activeDate: '', amount: ''});
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('linkType') || '';
  const envConfigObj: any = envConfig();
  const locale = theme.displayLocale || 'en';
  const contentType = urlParams.get('contentType') || null;

  const calendarDataSelected = useMemo(() => Object.keys(selectionObj).every((e) => selectionObj[e]), [selectionObj]);

  const handleChangeInput = (value: any, param: string) => {
    if (!value && param != 'notes') {
      if (param == 'tipJarAmount' && monetizeObj.type != 'tipJar') {
        return;
      }
      setVisitorDataError({
        ...visitorDataError,
        [param]: t('this_field_is_required', {
          lng: theme.displayLocale || 'en',
        }),
      });
    } else {
      setVisitorDataError({...visitorDataError, [param]: ''});
    }
    setVisitorData({
      ...visitorData,
      [param]: param == 'tipJarAmount' ? parseInt(value) : value,
    });
  };

  const validateData = () => {
    let valid = true;
    let obj = JSON.parse(JSON.stringify(visitorDataError));
    if (!visitorData.name) {
      obj = {
        ...obj,
        name: t('this_field_is_required', {lng: theme.displayLocale || 'en'}),
      };
      valid = false;
    }
    if (
      !visitorData.email ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(visitorData.email)
    ) {
      obj = {
        ...obj,
        email: t('not_a_valid_email', {lng: theme.displayLocale || 'en'}),
      };
      valid = false;
    }
    if (
      monetizeObj.type == 'tipJar' &&
      (!visitorData?.tipJarAmount ||
        visitorData?.tipJarAmount < monetizeObj.minPrice ||
        visitorData?.tipJarAmount > monetizeObj.maxPrice)
    ) {
      obj = {
        ...obj,
        tipJarAmount:
          visitorData?.tipJarAmount > monetizeObj.maxPrice ?
            `${t('tip_amount_cant_be_more_than')} ${currencyLabel} ${
              monetizeObj.maxPrice
            }` :
            `${t('tip_amount_cant_be_less_than')} ${currencyLabel} ${
              monetizeObj.minPrice
            }`,
      };
      valid = false;
    }
    if (!visitorData.phone) {
      obj = {
        ...obj,
        phone: t('this_field_is_required', {
          lng: theme.displayLocale || 'en',
        }),
      };
      valid = false;
    }
    if (!visitorData.countryCode) {
      obj = {
        ...obj,
        countryCode: t('this_field_is_required', {
          lng: theme.displayLocale || 'en',
        }),
      };
      valid = false;
    }
    setVisitorDataError(obj);
    return valid;
  };

  const getHeading = () => {
    switch (monetizeObj.type) {
      case 'videoMsg':
        return {
          text: monetizeObj.title,
          notes: 'whats_your_question',
          subTitle: 'ask_me_whatever_you_want',
        };
      case 'tipJar':
        return {
          text: monetizeObj.title,
          notes: 'something_you_would_like_to_tell_us',
          subTitle: 'send_a_tip_to_the_user',
        };
      case 'socialMediaPromote':
        return {
          text: monetizeObj.title,
          notes: 'what_would_you_like_to_promote',
          subTitle: 'get_your_brand_promoted_on_my_social_media',
        };
      case 'sellProduct':
        return {
          text: monetizeObj.title,
          notes: 'something_you_would_like_to_tell_us',
          subTitle: 'get_access_to_my_exclusive_digital_content',
        };
      case 'sellProductGuide':
        return {
          text: monetizeObj.title,
          notes: 'something_you_would_like_to_tell_us',
          subTitle: 'get_access_to_my_exclusive_digital_content_guide',
        };
      case 'shortCall':
        return {
          text: tapStatus == 'CAPTURED' ? 'book_a_slot' : monetizeObj.title,
          notes: 'please_share_anything_that_will_help_prepare_for_our_meeting',
          subTitle: 'connect_with_me_directly_to_get_your_questions_answered',
        };
      default:
        return {text: title || 'card_payment', notes: 'notes', subTitle: ''};
    }
  };

  const handlePhoneChange = (phone: any, country: any) => {
    const countryDial = country.dialCode;
    setVisitorData({...visitorData, phone: phone, countryCode: countryDial});
  };

  const fetchChargeStatus = async () => {
    try {
      setLoading(true);
      const chargeResponse = await nonHookRequest({
        method: 'GET',
        url: `/finance/api/tap/charges/${clientSecret}`,
        isShortUrl: true,
      });
      setTapStatus(chargeResponse?.status);
      setTapMsg(chargeResponse?.response?.message);
      setLinkSectionItem(chargeResponse?.linkSectionItem);
      if (
        chargeResponse?.status == 'CAPTURED' &&
        type == 'shortCall' && contentType === SectionItemContentType.CALENDLY && 
        !monetizeID
      ) {
        setStep('bookingSlot');
      } else {
        setStep('card');
      }
      setLoading(false);
    } catch (error: any) {
      toast(
          <span style={{color: 'black'}}>
            {error?.message || 'Something went wrong!'}
          </span>,
          {autoClose: 3000},
      );
    }
  };

  const newSuccessModalLinkTypes = ['sellProductGuide', 'sellProduct'];

  const getChargeHtml = () => {
    switch (tapStatus) {
      case 'CAPTURED':
        return (
          <div className={styles.payment_done}>
            {(newSuccessModalLinkTypes.includes(urlParams.get('linkType')!)) ? 
            <DetailedSuccessModalContent theme={theme} handleClose={handleClose} linkSectionItem={linkSectionItem} /> : 
            <GenericSuccessModalContent theme={theme} />}
          </div>
        );
      default:
        return (
          <div className={styles.payment_done}>
            <CancelIcon fontSize="large" style={{color: 'red'}} />
            <div
              style={{textAlign: 'center'}}
            >{`The transaction has been ${tapMsg?.toLowerCase()}. Please try with the correct details or try again later.`}</div>
          </div>
        );
    }
  };

  const handleMonetizationLinkClick = async (data: any) => {
    await nonHookRequest({
      url: '/finance/api/clevertap/monetization_links',
      method: 'POST',
      body: {...data, productType: 'Link'},
      isShortUrl: true,
    });
    GoSell.openPaymentPage();
  };

  const handleNextStep = async () => {
    if (step == 'preview') {
      setStep('form');
    } else if (step == 'form' && monetizeObj?.contentType !== SectionItemContentType.GOOGLE_CALENDAR) {
      const isValid = validateData();
      if (isValid) {
        handleMonetizationLinkClick(monetizeObj?.type == 'tipJar' ? {link_id: monetizeID, amount: visitorData.tipJarAmount * 100, currency: monetizeObj?.currency?.toUpperCase() ?? 'USD', clevertapEvent: 'tip_jar_intent', sectionItemPriceValue: 'default'} : {link_id: monetizeID, clevertapEvent: 'payment_intent', sectionItemPriceValue: 'default'});
      }
    } else if (step == 'form' && monetizeObj?.contentType === SectionItemContentType.GOOGLE_CALENDAR) {
      setStep('googleCalendarView');
    } else if (step == 'googleCalendarView' && calendarDataSelected) {
      handleMonetizationLinkClick({link_id: monetizeID, clevertapEvent: 'payment_intent', sectionItemPriceValue: selectionObj?.activeDuration});
    }
  };

  const getMetaData = () => {
    const calendarPayload = step == 'googleCalendarView' ? {startDate: moment(`${selectionObj?.activeDate} ${selectionObj?.activeSlot?.startTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:SS'), endDate: moment(`${selectionObj?.activeDate} ${selectionObj?.activeSlot?.startTime}`, 'YYYY-MM-DD hh:mm A').add(selectionObj?.activeDuration, 'm').format('YYYY-MM-DDTHH:mm:SS'), timezone: selectionObj?.timezone} : '';
    return {
      linkId: monetizeID,
      userId: userId,
      email: visitorData.email,
      name: visitorData.name,
      phone: `${visitorData.countryCode}${visitorData.phone.slice(visitorData.countryCode.length, visitorData.phone.length)}`,
      notes: visitorData.notes,
      productType: 'Link',
      details: calendarPayload == '' ? undefined : JSON.stringify(calendarPayload),
    };
  };

  useEffect(() => {
    // api hit for intent
    if (clientSecret) {
      if (type == 'shortCall' && !monetizeID) {
        const name = urlParams.get('name') || '';
        const email = urlParams.get('email') || '';
        const notes = urlParams.get('notes') || '';
        setVisitorData({
          ...visitorData,
          name: name,
          email: email,
          notes: notes,
        });
      }
      fetchChargeStatus();
    }
  }, [clientSecret]);

  useEffect(() => {
    if (step && step == 'bookingSlot') {
      toast(
          <span style={{color: 'black'}}>
            {t('your_payment_is_successfully_done', {
              lng: theme.displayLocale || 'en',
            })}
          </span>,
          {autoClose: 3000},
      );
    }
  }, [step]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <div className={styles.cross} onClick={handleClose}>
              <CloseIcon />
            </div>
            {step === 'card' && monetizeID && (
              <div className={styles.back} onClick={() => setStep('form')}>
                <ArrowBackIcon />
              </div>
            )}
            {(monetizeObj.type === 'sellProductGuide' || monetizeObj.type === 'sellProduct') && !loading ? <Preview
              theme={theme}
              monetizeObj={monetizeObj}
              linksUser={linksUser}
              getHeading={getHeading}
            /> : ''}

            {(newSuccessModalLinkTypes.includes(urlParams.get('linkType')!)) ? null : <div className={styles.heading}>
              {t(getHeading()?.text, {lng: theme.displayLocale || 'en'})}
            </div>}

            {monetizeObj.note && step==='form' && <SafeHtmlDisplay htmlContent={monetizeObj.note} />}
            <div className={styles.content_body}>
              {['form', 'preview'].includes(step) && !loading && (
                <form>
                  {(
                    <>
                      {monetizeObj.type === 'tipJar' && (
                        <TipJar
                          monetizeObj={monetizeObj}
                          visitorData={visitorData}
                          setVisitorData={setVisitorData}
                          visitorDataError={visitorDataError}
                          currencyLabel={getCurrencyLabel(monetizeObj.currency)}
                          handleChangeInput={handleChangeInput}
                        />
                      )}
                      <InputField
                        label={t('full_name', {
                          lng: theme.displayLocale || 'en',
                        })}
                        value={visitorData.name}
                        error={visitorDataError.name}
                        onChange={(e) =>
                          handleChangeInput(e.target.value, 'name')
                        }
                      />
                      <InputField
                        label={t('email', {lng: theme.displayLocale || 'en'})}
                        type="email"
                        value={visitorData.email}
                        error={visitorDataError.email}
                        onChange={(e) =>
                          handleChangeInput(e.target.value, 'email')
                        }
                        style={{marginBottom: '10px'}} // Inline style
                      />
                      <PhoneNumber
                        localization={isArabic(i18n.language) ? ar : undefined}
                        placeholder=" " // Leave this empty to trigger the floating label
                        value={visitorData.phone}
                        onChange={(v, c) => handlePhoneChange(v, c)}
                        country="ae"
                        inputClass={styles.phoneInput}
                        specialLabel={t('mobileNumber', {
                          lng: theme.displayLocale || 'en',
                        })}
                        inputProps={{
                          name: 'phone',
                          required: true,
                          id: 'phone',
                        }}
                      />
                      {(visitorDataError.phone ||
                        visitorDataError.countryCode) && (
                        <div className={styles.error}>
                          {t(visitorDataError.phone)}
                        </div>
                      )}
                      {monetizeObj.type === 'sellProductGuide' || monetizeObj.type === 'sellProduct' ? (
                        ''
                      ) : (
                        <TextInput
                          value={visitorData.notes}
                          onChange={(e) =>
                            handleChangeInput(e.target.value, 'notes')
                          }
                          label={t(getHeading()?.notes, {
                            lng: theme.displayLocale || 'en',
                          })}
                          multiline
                          minRows={5}
                          containerStyle={{marginTop: '1rem'}}
                        />
                      )}
                      <div className={styles.row}>
                        {monetizeObj.type === 'tipJar' ? (
                          visitorData?.tipJarAmount ? (
                            <div className={styles.totalSection}>
                              <div className={styles.totalLabel}>
                                {t('total', {
                                  lng: theme.displayLocale || 'en',
                                })}
                              </div>
                              <div className={styles.dottedLine}></div>
                              <div className={styles.totalPrice}>
                                {currencyLabel} {visitorData.tipJarAmount}
                              </div>
                            </div>
                          ) : null
                        ) : (
                          <div className={styles.totalSection}>
                            <div className={styles.totalLabel}>
                              {t('total', {lng: theme.displayLocale || 'en'})}
                            </div>
                            <div className={styles.dottedLine}></div>
                            <div className={styles.totalPrice}>
                              {currencyLabel} {monetizeObj?.amount[0]?.amount / 100}
                            </div>
                          </div>

                        )}
                      </div>
                      {<CheckboxField
                        checked={termsOfService}
                        onChange={() => setTermsOfService(!termsOfService)}
                        label={
                          <Trans i18nKey='term_and_conditions'>
                          Accept <a href="https://app.al.fan/terms-and-condition-buyer" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://app.al.fan/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                          </Trans>
                        }
                      />}
                    </>
                  )}
                  <SubmitButton
                    loading={loading}
                    step={step}
                    linkType={monetizeObj.type}
                    visitorData={visitorData}
                    termsOfService={termsOfService}
                    onClick={handleNextStep}
                    calendarDataSelected={calendarDataSelected}
                  />
                </form>
              )}
              {['googleCalendarView'].includes(step) && !loading && (
                <form>
                  {(
                    <>
                      <BookCallSlotPicker selectionObj={selectionObj} setSelectedObj={setSelectedObj} monetizeObj={monetizeObj} language={theme.displayLocale} currencyLabel={currencyLabel} monetizeID={monetizeID} />
                      {<CheckboxField
                        checked={termsOfService}
                        onChange={() => setTermsOfService(!termsOfService)}
                        label={
                          <Trans i18nKey='term_and_conditions'>
                          Accept <a href="https://app.al.fan/terms-and-condition-buyer" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://app.al.fan/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                          </Trans>
                        }
                      />}
                    </>
                  )}
                  <SubmitButton
                    loading={loading}
                    step={step}
                    linkType={monetizeObj.type}
                    visitorData={visitorData}
                    termsOfService={termsOfService}
                    onClick={handleNextStep}
                    calendarDataSelected={calendarDataSelected}
                  />
                </form>
              )}
              {step === 'card' && clientSecret && !loading && getChargeHtml()}
              {step === 'bookingSlot' && contentType === SectionItemContentType.CALENDLY && (
                <CalendarBooking
                  url={`${monetizeObj.url}?hide_gdpr_banner=1&name=${visitorData.name}&email=${visitorData.email}&notes=${visitorData.notes}`}
                />
              )}
              {loading && <LoaderComponent />}
            </div>
          </div>
        </div>
      </div>
      {monetizeID && (
        <GoSell
          gateway={{
            publicKey: envConfigObj.tapPublicKey as string,
            merchantId: envConfigObj[
                mIDMap[monetizeObj.currency?.toLowerCase()] || mIDMap['usd']
            ] as string,
            language: 'en',
            contactInfo: true,
            supportedCurrencies: 'all',
            supportedPaymentMethods: 'all',
            saveCardOption: true,
            customerCards: true,
            notifications: 'standard',
            backgroundImg: {
              url: '',
              opacity: '0.5',
            },
            labels: {
              cardNumber: 'Card Number',
              expirationDate: 'MM/YY',
              cvv: 'CVV',
              cardHolder: 'Name on Card',
              actionButton: 'Pay',
            },
            style: {
              base: {
                'color': '#535353',
                'lineHeight': '18px',
                'fontFamily': 'sans-serif',
                'fontSmoothing': 'antialiased',
                'fontSize': '16px',
                '::placeholder': {
                  color: 'rgba(0, 0, 0, 0.26)',
                  fontSize: '15px',
                },
              },
              invalid: {
                color: 'red',
                iconColor: '#fa755a',
              },
            },
          }}
          customer={{
            first_name: visitorData.name,
            email: visitorData.email,
            phone: {
              country_code: visitorData.countryCode,
              number: visitorData.phone.slice(visitorData.countryCode.length),
            },
          }}
          order={{
            amount:
              monetizeObj.type === 'tipJar' ?
                visitorData.tipJarAmount :
                (step === 'googleCalendarView' ? selectionObj?.amount/100 : monetizeObj.amount[0]?.amount/100),
            currency: monetizeObj.currency?.toUpperCase() ?? 'USD',
            shipping: null,
            taxes: null,
          }}
          transaction={{
            mode: 'charge',
            charge: {
              saveCard: false,
              threeDSecure: true,
              metadata: getMetaData(),
              receipt: {
                email: false,
                sms: true,
              },
              redirect: `${window.location.origin}${window.location.pathname}?email=${visitorData.email}&linkType=${monetizeObj.type}&bookUrl=${monetizeObj.url}&name=${visitorData?.name}&notes=${visitorData?.notes}&contentType=${monetizeObj?.contentType}`,
              post: `${envConfig(true).baseUrl}/finance/api/tap/webhook`,
            },
          }}
        />
      )}
    </>
  );
};

export default ChargeCardTapModal;
