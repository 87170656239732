/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint new-cap: ["error", { "capIsNewExceptionPattern": "^window\\.." }] */
import React, {useRef, useEffect} from "react";
import styles from "./Header.module.scss";
import logo from "../../assets/images/alfan-logo.svg";
import {useState} from "react";
import {headerRoutes} from "../../constants/routes";
import {NavLink, useHistory} from "react-router-dom";
import {selectMe} from "../../features/stores/userSlicer";
import {useAppSelector} from "../../app/hooks";
import {store} from "../../app/store";
import defaultAvatar from "../../assets/images/al_default_avatar.png";
import {logOut} from "../../features/Login/login";
import useWindowWidth from "../../hooks/useWindowWidth";
import CopyLinkCard from "../CopyLinkCard/CopyLinkCard";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import {useSelector} from "react-redux";
import {setShouldSave} from "../../features/stores/changeDataSlicer";
import WarningDialog from "../UnsavedChanges/WarningDialog";
import {useTranslation} from "react-i18next";
import {
  getPreferredLocale,
  isRtl,
  haveSegmentPermission,
} from "../../features/util";
import LanguagePicker from "../LanguagePicker/LanguagePicker";
import nonHookRequest from "../../features/API/nonHookRequest";
import {getConfig} from "../../features/stores/configSlicer";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import {getCurrencyFullLabel} from "../../constants/currencies";
import {isProdSite} from "../../Helper/generalHelper";
import SettingsIcon from "@material-ui/icons/Settings";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {makeStyles} from "@material-ui/core/styles";
import PlatformSwitcher from "../PlatformSwitcher";

const selectChangeData = (state: any) => state.changeData.changeData;
const selectShouldSave = (state: any) => state.changeData.shouldSaveEverything;

const useStyles = makeStyles({
  root: {
    "borderRadius": "27px",
    "width": "auto",
    "boxShadow":
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em",
    "& .MuiBottomNavigationAction-root": {
      "@media (max-width: 420px)": {
        minWidth: "50px",
        padding: "0",
      },
      "& .MuiBottomNavigationAction-wrapper": {
        "& .MuiBottomNavigationAction-label": {
          "fontSize": "12px",
          "@media (max-width: 360px)": {
            fontSize: "11px",
          },
        },
      },
    },
  },
});

const Header = () => {
  const {t, i18n} = useTranslation();
  const [active, setActive] = useState(window?.location?.pathname || "");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [clickedLogOut, setClickedLogOut] = useState(false);
  const [earningBalance, setEarningBalance] = useState<any>([]);
  const dataChanged = useSelector(selectChangeData);
  const user = selectMe(useAppSelector(store.getState));
  const config = useSelector(getConfig).data || [];
  const mobileView = 1024;
  const classes = useStyles();
  const node = useRef(null);
  useOutsideAlerter(node, setIsMenuOpen);
  const history = useHistory();
  const linksUser = selectMe(useAppSelector(store.getState)).me.linksUser;
  const userAvatar = linksUser?.avatar ? linksUser.avatar.url : defaultAvatar;
  const mobile = useWindowWidth();
  const transparentAvatarBg =
    active == "" ||
    [
      "/profile/myprofile",
      "/profile/design",
      "/profile/home",
      "/profile/deals",
      "/profile/settings",
      "/profile/profile-icons",
      "/",
    ].includes(active);
  const greyedAvatarBg = [
    "/profile/setting/update-password",
    "/profile/setting/contract",
    "/profile/setting/personal-information",
  ].includes(active);
  let headerLinksFiltered =
    haveSegmentPermission(config, "monetize") &&
    !haveSegmentPermission(config, "monetizeBlocklist") ?
      headerRoutes :
      headerRoutes.filter(
          (e: any) => e.key != "requestdashboard",
        );
  headerLinksFiltered =
        haveSegmentPermission(config, "referralEligibleUsers") ?
        headerLinksFiltered :
          headerRoutes.filter(
              (e: any) => e.key != "referrals",
            );
  const currencyMap = haveSegmentPermission(config, "currencyFactor") || [];
  if (!!!user?.me?.inNetwork || isProdSite()) {
    headerLinksFiltered = headerLinksFiltered.filter(
      (e: any) => e.key != "network",
    );
  }

  const setPathActivation = (match: unknown, pathname: string) => {
    if (pathname === "/") {
      setActive("/profile/home");
      if (!match) return false;
      return true;
    }
    if (!match) {
      setActive(pathname);
      return false;
    }
    if (['/profile/home', '/profile/profile-icons'].includes(pathname)) {
      setActive('/profile/home');
      return true;
    }
    if (pathname.includes("/profile/request-dashboard")) {
      setActive("/profile/request-dashboard");
      return true;
    }
    setActive("/");
    return true;
  };

  const openRoute = (url: string) => {
    window.location.href = url;
  };

  const headerLinks = headerLinksFiltered.map((route: any) => {
    const activeRoute =
      route.route === active ||
      (active && active.includes(route?.pathMatch)) ||
      (route.activatedRoute && route.activatedRoute.includes(active));

    return (
      <div
        className={`${styles.route} ${activeRoute ? styles.active : ""}`}
        key={route.key}
      >
          <NavLink
            onClick={route.onClick}
            to={route.route}
            className={styles.route}
            activeClassName={route.key == 'referrals' ? '': styles.route_active}
            isActive={(match, location) =>
              setPathActivation(match, location?.pathname)
            }
          >
            <img
              src={activeRoute ? route.activeIcon : route.icon}
              alt={route.key}
              className={styles.route_img}
            />
            <p
              className={
                activeRoute ? styles.route_active : styles.route_description
              }
            >
              {t(route.name)}
            </p>
          </NavLink>
      </div>
    );
  });

  const getEarning = async () => {
    try {
      const earningResponse = await nonHookRequest({
        method: "GET",
        url: "/finance/api/wallet",
        body: {},
        isShortUrl: true,
      });
      if (earningResponse) {
        setEarningBalance(earningResponse);
      }
    } catch (error) {
      console.log("something went wrong!");
    }
  };

  const showSupportWidget = () => {
    if (window.Trengo && window.Trengo.Api && window.Trengo.Api.Widget) {
      window.Trengo.Api.Widget.open('chat');
    }
    // window.FreshworksWidget("open", "ticketForm", {
    //   name: user?.me?.linksUser?.nickname,
    //   email: user?.me?.email,
    //   group_id: 2043001841734 /** ID refer to group to which ticket will be assigned https://alfan.freshdesk.com/a/admin/groups/2043001841734/edit/agents */,
    // });
  };

  // useEffect(() => {
  //   window.Trengo = window.Trengo || {};
  //   if (mobile < 700) {
  //     window.Trengo.panelHeader = false;
  //     window.Trengo.launcherOnlineStatus = false;
  //   }
  // }, []);

  // useEffect(() => {
  //   if (mobile < 700) {
  //     window.FreshworksWidget("hide", "launcher");
  //   }
  // }, []);

  useEffect(() => {
    if (active && mobile <= mobileView) {
      if (active.includes("/earnings/dashboard")) {
        window.location.href = active;
      } else {
        history.push(`${active}${window.location.search}`);
      }
    }
  }, [active]);

  useEffect(() => {
    if (user.loginStatus == "LOGGED_IN") {
      setTimeout(() => getEarning());
    }
  }, [user]);

  return (
    <>
      {dataChanged && (
        <WarningDialog
          key={Date.now()}
          open={clickedLogOut}
          titleText={t("close_without_saving")}
          contentText={t("unsaved_changes_warning")}
          cancelButtonText={t("save_changes")}
          confirmButtonText={t("discard_changes")}
          onCancel={() => {
            store.dispatch(setShouldSave(true));
            setTimeout(() => {
              logOut();
              // window.location.href='/';
            }, 500);
          }}
          onConfirm={() => {
            logOut();
            // window.location.href='/';
          }}
          onModalClose={() => {}}
        />
      )}
      {mobile > mobileView && (
        <div className={styles.container}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {/* <div className={styles.logo_container} onClick={(e)=>{
            location.pathname='';
            setActive('/profile/myprofile');
          }}>
            <object style={{pointerEvents: 'none'}} data={logo} className={styles.logo} type="image/svg+xml"></object>
          </div> */}
            <PlatformSwitcher />
            {mobile > mobileView && (
              <div className={styles.routes_container}>{headerLinks}</div>
            )}
          </div>
          <div
            className={`${styles.right_side} ${
              transparentAvatarBg ? styles.blur_bg : ""
            } ${greyedAvatarBg ? styles.grey_bg : ""}`}
          >
            <LanguagePicker
              onChange={() => {
                window.clevertap.profile.push({
                  Site: {
                    Language: getPreferredLocale(),
                  },
                });
              }}
              newUi={true}
            ></LanguagePicker>
            <div
              className={styles.loggedUser_container}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {/* {earningBalance[0]?.balance && haveSegmentPermission(config, 'monetize') && !haveSegmentPermission(config, 'monetizeBlocklist') && mobile > 720 && <div className={styles.earning_container}>
              <div>Earnings</div>
              {earningBalance.filter((e: any) => e.currency != 'sar').map((ele: any) => {
                return <div key={ele.currency}><div className={styles.balance}>{(Number(earningBalance.filter((e: any) => e.currency == ele.currency)[0]?.balance)/(currencyMap.filter((e: any) => e.currency == ele.currency)[0]?.conversionFactor || 100)).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>{<div className={styles.currency}>{getCurrencyFullLabel(ele.currency)}</div>}</div>;
              })}
            </div>} */}
              <img className={styles.avatar} src={userAvatar} />
              <div className={styles.names_container}>
                {linksUser?.fullname}
              </div>
              {isMenuOpen && (
                <div
                  className={styles.logout_menu}
                  style={
                    isRtl(i18n.language) ? {left: "43px"} : {right: "43px"}
                  }
                  ref={node}
                >
                  <div onClick={showSupportWidget}>
                    <ContactSupportOutlinedIcon fontSize="large" />
                    <p>{t("support")}</p>
                  </div>
                  <NavLink to="/profile/settings">
                    <div onClick={() => {}}>
                      <SettingsIcon fontSize="large" />
                      <p>{t("advanced")}</p>
                    </div>
                  </NavLink>
                  <div
                    onClick={() => {
                      if (dataChanged) {
                        setClickedLogOut(true);
                      } else {
                        logOut();
                      }
                    }}
                  >
                    <ExitToAppOutlinedIcon fontSize="large" />
                    <p>{t("logout")}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {mobile <= mobileView && (
        <>
          <div className={styles.copy_link}>
            <div className={styles.mobile_head_user}>
              {/* <div onClick={(e)=>{
            location.pathname='';
            setActive('/profile/myprofile');
          }}>
            <object style={{pointerEvents: 'none'}} data={logo} className={styles.logo} type="image/svg+xml"></object>
          </div> */}
              <PlatformSwitcher />
              <div className={styles.loggedUser_container}>
                <LanguagePicker
                  onChange={() => {
                    window.clevertap.profile.push({
                      Site: {
                        Language: getPreferredLocale(),
                      },
                    });
                  }}
                  newUi={true}
                ></LanguagePicker>
                <img
                  className={styles.avatar}
                  src={userAvatar}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                />
                <div className={styles.names_container}>
                  {linksUser?.fullname}
                </div>
                {isMenuOpen && (
                  <div
                    className={styles.logout_menu}
                    style={
                      isRtl(i18n.language) ?
                        {left: "43px"} :
                        {right: "43px"}
                    }
                    ref={node}
                  >
                    <div onClick={showSupportWidget}>
                      <ContactSupportOutlinedIcon fontSize="large" />
                      <p>{t("support")}</p>
                    </div>
                    <NavLink to="/profile/settings">
                      <div onClick={() => {}}>
                        <SettingsIcon fontSize="large" />
                        <p>{t("advanced")}</p>
                      </div>
                    </NavLink>
                    <div
                      onClick={() => {
                        if (dataChanged) {
                          setClickedLogOut(true);
                        } else {
                          logOut();
                          // window.location.href='/';
                        }
                      }}
                    >
                      <ExitToAppOutlinedIcon fontSize="large" />
                      <p>{t("logout")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <CopyLinkCard />
          </div>
          <div className={styles.bottom_navigation}>
            <BottomNavigation
              value={active}
              onChange={(event: React.ChangeEvent<{}>, path: string) =>
                setPathActivation(null, path)
              }
              className={classes.root}
            >
              {headerLinksFiltered.map((route: any) => (
                <BottomNavigationAction
                  showLabel
                  key={route.route}
                  label={t(route.name)}
                  value={route.route}
                  icon={
                    <img
                      src={
                        route.route === active ||
                        active?.includes(route?.pathMatch) ||
                        route?.activatedRoute?.includes(active) ?
                          route.activeIcon :
                          route.icon
                      }
                      alt={route.key}
                      className={styles.route_img}
                    />
                  }
                />
              ))}
            </BottomNavigation>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
